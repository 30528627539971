export function getAreaName(area) {
	var areaName = '';
	if (area.building != null) {
		areaName += `${area.building} Épület `;
	}
	if (area.floor != null) {
		areaName += `${area.floor}. emelet `;
	}
	areaName += area.name;
	if (area.number != null) {
		areaName += ` ${area.number}.`;
	}
	return areaName;
}

export function getAreaNameWithoutBuilding(area) {
	var areaName = '';
	if (area.floor != null) {
		areaName += `${area.floor}. emelet `;
	}
	areaName += area.name;
	if (area.number != null) {
		areaName += ` ${area.number}.`;
	}
	return areaName;
}

export function getUserName(user) {
	return`${user.lastName} ${user.firstName}`;
}
