import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import {
	updateVenue,
	getTemplates,
	getUsersOnOrg,
	getCleaningActivitiesForOrg
} from '../../api/manager';
import { getSelectedOrg } from '../../firebase/auth';

import TopNavigationBar from '../../utilities/TopNavigationBar';

import { Paper, Grid, Select, MenuItem, InputLabel, Button } from '@mui/material';
import EditVenueView from './EditVenueView';
import LoadingDialog from '../../utilities/LoadingDialog';

const useStyles = makeStyles((theme) => ({
	root: {
		felxGrow: 1,
		padding: 10
	}
}));

export default function Venue() {
	const classes = useStyles();
	const history = useHistory();
	const locationQuery = new URLSearchParams(useLocation().search);
	const location = useLocation();
	const venue = useSelector((state) => state.venues[locationQuery.get('id')])
	// var venue = {};
	// const [ venue, setVenue ] = React.useState({});
	const [ venues, setVenues ] = React.useState([]);
	const [ templates, setTemplates ] = React.useState([]);
	const [ templatesMap, setTemplatesMap ] = React.useState([]);
	const [ users, setUsers ] = React.useState([]);
	const [ cleaningActivities, setCleaningActivities ] = React.useState([]);
	const [ cleaningActivitiesMap, setCleaningActivitiesMap ] = React.useState([]);
	const [ openLoadingDialog, setOpenLoadingDialog ] = React.useState(false);

	useEffect(() => {
		if (venue) {
			venue.id = locationQuery.get('id')
			// venue = useSelector((state) => state.venues[locationQuery.get('id')])
			// if (location.state) {
			// 	setVenue(location.state.venue);
			// 	// refreshReportsByVenue(location.state.venue);
			// } else {
			// 	getVenuesWithAreas(getSelectedOrg())
			// 		.then((result) => {
			// 			const venueId = locationQuery.get('id');
			// 			const tempVenue = result.data.venues[venueId];
			// 			tempVenue.id = venueId;
			// 			setVenue(tempVenue);
			// 			// refreshReportsByVenue(tempVenue);
			// 		})
			// 		.catch((error) => {
			// 			console.log('Error happened in Venues.js while retriving Venues' + error);
			// 			// setIsLoading(false);
			// 		});
			// }
		}
		if (location.state) {
			setTemplates(location.state.templates);
			setTemplatesMap(location.state.templatesMap);
			setCleaningActivities(location.state.cleaningActivities);
			setCleaningActivitiesMap(location.state.cleaningActivitiesMap);

			// refreshReportsByVenue(location.state.venue);
		} else {
			fetchTemplates();
			fetchCleaningActivities();
		}
		if (location.state) {
			setUsers(location.state.users);
			// refreshReportsByVenue(location.state.venue);
		} else {
			fetchUsers();
		}
		// if (templates.length == 0) fetchTemplates();
		// if (users.length == 0) fetchUsers();
	}, []);

	const updateBrowserHistory = () => {
		const tempState = location.state;
		tempState.venue = venue;
		history.replace({ pathname: location.pathname, search: location.search, state: tempState });
	};
	// const fetchVenues = () => {
	// 	getVenuesWithAreas(getSelectedOrg())
	// 		.then((result) => {
	// 			setVenues(result.data.results);
	// 		})
	// 		.catch((error) => {
	// 			console.log('Error happened in Venues.js while retriving Venues' + error);
	// 		});
	// };

	const fetchTemplates = () => {
		getTemplates(getSelectedOrg())
			.then((result) => {
				setTemplates(result);
				var templatesMap = {};
				result.map((template) => (templatesMap[template.id] = template));
				setTemplatesMap(templatesMap);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving templates' + error);
			});
	};

	const fetchCleaningActivities = () => {
		getCleaningActivitiesForOrg(getSelectedOrg())
			.then((result) => {
				setCleaningActivities(result);
				var tempCleaningActivitiesMap = {};
				result.map((cleaningActivity) => (tempCleaningActivitiesMap[cleaningActivity.id] = cleaningActivity));
				setCleaningActivitiesMap(tempCleaningActivitiesMap);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving templates' + error);
			});
	};

	const fetchUsers = () => {
		getUsersOnOrg(getSelectedOrg())
			.then((result) => {
				setUsers(result.data.results);
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving users' + error);
			});
	};

	const handleUpdateVenue = (venue) => {
		setOpenLoadingDialog(true);
		updateVenue(getSelectedOrg(), venue)
			.then((result) => {
				setOpenLoadingDialog(false);
				updateBrowserHistory();
			})
			.catch((error) => {
				console.log('Error happened while saving Venue' + error);
			});
	};

	
	return (
		<div>
			{venue && <TopNavigationBar title={venue.name} />}
			<LoadingDialog open={openLoadingDialog} title="Töltés folyamatban..." />
			{venue && (
				<EditVenueView
					venue={venue}
					templates={templates}
					templatesMap={templatesMap}
					cleaningActivities={cleaningActivities}
					cleaningActivitiesMap={cleaningActivitiesMap}
					users={users}
					updateVenue={handleUpdateVenue}
					updateBrowserHistory={updateBrowserHistory}
				/>
			)}
		</div>
	);
}
