import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const events = [
	{
		id: 0,
		title: 'Board meeting',
		start: new Date(2018, 0, 29, 9, 0, 0),
		end: new Date(2018, 0, 29, 13, 0, 0),
		resourceId: 1
	},
	{
		id: 1,
		title: 'MS training',
		allDay: true,
		start: new Date(2018, 0, 29, 14, 0, 0),
		end: new Date(2018, 0, 29, 16, 30, 0),
		resourceId: 2
	},
	{
		id: 2,
		title: 'Team lead meeting',
		start: new Date(2018, 0, 29, 8, 30, 0),
		end: new Date(2018, 0, 29, 12, 30, 0),
		resourceId: 3
	},
	{
		id: 11,
		title: 'Birthday Party',
		start: new Date(2018, 0, 30, 7, 0, 0),
		end: new Date(2018, 0, 30, 10, 30, 0),
		resourceId: 4
	}
];

const resourceMap = [
	{ resourceId: '1121dsds', resourceTitle: 'Board room' },
	{ resourceId: 'dsdsds2', resourceTitle: 'Training room' },
	{ resourceId: 'dsdsd3', resourceTitle: 'Meeting room 1' },
	{ resourceId: 'dsdsds4', resourceTitle: 'Meeting room 2' }
];

export default function CalendarView({ workerPositions }) {
	const getResourceMap = () => {
		var resourceMap = [];
		workerPositions.forEach((element) => {
			resourceMap.add({ resourceId: element.id, resourceTitle: element.name });
		});
		return resourceMap;
	};

	return (
		<div className="CalendarView">
			<Calendar
				events={events}
				localizer={localizer}
				defaultView={Views.DAY}
				views={[ 'day', 'week', 'work_week' ]}
				step={60}
				defaultDate={new Date()}
				resources={workerPositions}
				resourceIdAccessor="id"
				resourceTitleAccessor="name"
			/>
		</div>
	);
}
