import React, { useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, Typography, Chip, Divider } from '@mui/material';

import WorksheetsOnArea from './WorksheetsOnArea';

export default function AreaAndActivities({ area, venue, worksheets, positionAreas }) {
	const GrayCaptionTextTypography = withStyles({
		root: {
			color: '#9e9e9e'
		}
	})(Typography);

	return (
		<div>
			<Grid container alignItems="flex-start" justifyContent="flex-start">
				<Grid item xs={2}>
					<GrayCaptionTextTypography variant="caption">Terület:</GrayCaptionTextTypography>
					<br />
					<Typography variant="subtitle1">
						{area ? area.name + ' ' + (area.number ? area.number : '') : 'Törölt terület'}
					</Typography>
					<br />
					<Typography variant="subtitle2">
						{area && area.building ? area.building + ' épület' : ''}{' '}
						{area && area.floor ? area.floor + '. szint' : ''}
					</Typography>
					<GrayCaptionTextTypography variant="caption">Kategória:</GrayCaptionTextTypography>
					<Typography variant="subtitle2">
						{area && area.category != null ? venue.categories[area.category].name : ''}
					</Typography>
				</Grid>
				<Grid item xs={10}>
					<GrayCaptionTextTypography variant="caption">Feladatlisták:</GrayCaptionTextTypography>
					{area ? (
						<WorksheetsOnArea
							worksheets={worksheets}
							positionArea={positionAreas[area.id]}
							editable={false}
						/>
					) : (
						''
					)}
				</Grid>
			</Grid>
			<Divider light variant="middle" />
		</div>
	);
}
