import React, { useEffect } from 'react';

import { MenuItem, CardHeader, Box, Button } from '@mui/material';
import WeeklyRRulePicker from './RRulePicker/WeeklyRRulePicker';
import DailyRRulePicker from './RRulePicker/DailyRRulePicker';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import Select from '@mui/material/Select';
import MonthlyRRulePicker from './RRulePicker/MonthlyRRulePicker';

const frequencyArray = [ 'DAILY', 'WEEKLY', 'MONTHLY' ];

const translateFreqKey = (key) => {
	if (key == 'WEEKLY') {
		return 'Heti';
	} else if (key == 'DAILY') {
		return 'Napi';
	} else if (key == 'MONTHLY') {
		return 'Havi';
	} else if (key == 'YEARLY') {
		return 'Évi';
	}
};

function getFreqIndexFromWorksheet(worksheet) {
	if (worksheet && worksheet.freq) {
		return frequencyArray.indexOf(worksheet.freq);
	}
	return -1;
}

export default function WorksheetCard({ worksheetOnArea, worksheet, editable, deleteWorksheetCard, worksheetId }) {
	const [ freqIndex, setFreqIndex ] = React.useState(getFreqIndexFromWorksheet(worksheetOnArea));

	useEffect(() => {
		// generateAreasWithActivities();
		if (freqIndex != null && frequencyArray[freqIndex] != worksheetOnArea.freq) {
			setFreqIndex(getFreqIndexFromWorksheet(worksheetOnArea));
		}
	});

	const handleFreqChange = (event) => {
		setFreqIndex(event.target.value);
		worksheetOnArea.freq = frequencyArray[event.target.value];
	};

	const renderFreq = () => {
		if (frequencyArray[freqIndex] == 'DAILY') {
			return <DailyRRulePicker worksheet={worksheetOnArea} editable={editable} />;
		}
		if (frequencyArray[freqIndex] == 'WEEKLY') {
			return <WeeklyRRulePicker worksheet={worksheetOnArea} editable={editable} />;
		}
		if (frequencyArray[freqIndex] == 'MONTHLY') {
			return <MonthlyRRulePicker worksheet={worksheetOnArea} editable={editable} />;
		}
		return '';
	};

	return (
		<Card>
			<CardHeader
				style={{ padding: '8px' }}
				titleTypographyProps={{ color: 'primary', variant: 'subtitle1' }}
				action={
					<Select
						disabled={!editable}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={freqIndex}
						onChange={handleFreqChange}
					>
						{frequencyArray.map((element, index) => (
							<MenuItem key={index} value={index}>
								{translateFreqKey(element)}
							</MenuItem>
						))}
					</Select>
				}
				title={worksheet ? worksheet.name : 'Törölt'}
			/>
			<Box m={1}>{worksheetOnArea && renderFreq()}</Box>
			{deleteWorksheetCard && editable ? (
				<CardActions>
					<Button size="small" color="secondary" onClick={() => deleteWorksheetCard(worksheetId)}>
						Törlés
					</Button>
				</CardActions>
			) : (
				''
			)}
		</Card>
	);
}
