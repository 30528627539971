import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	FormControl,
	NativeSelect,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Avatar,
	Grid,
	TextField,
	Typography,
	Divider,
	AccordionSummary,
	AccordionDetails,
	Accordion,
	Box
} from '@mui/material';

import { getAreaName } from '../../../utilities/StringHelper';
import AreasAndRulesTableList from './AreasAndRulesTable';
import CleaningActivitiesTable from './CleaningActivitiesTable';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 10
	},
	expandable: {
		width: '100%'
	},
	panel1cheader: {
		background: '#039be5'
	},
	heading: {
		fontSize: useTheme().typography.pxToRem(15),
		display: 'block'
	},
	secondaryHeading: {
		fontSize: useTheme().typography.pxToRem(15),
		color: useTheme().palette.text.secondary
	},
	icon: {
		verticalAlign: 'bottom',
		height: 20,
		width: 20
	},
	helper: {
		borderLeft: `2px solid ${useTheme().palette.divider}`,
		padding: useTheme().spacing(1, 2)
	},
	separator: {
		margin: '0px 10px 0px 10px'
	},
	link: {
		color: useTheme().palette.primary.main,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
}));

export default function WorksheetsExpansionView({
	worksheet,
	worksheetId,
	cleaningActivitiesMap,
	venue,
	setWorksheetIsLoading
}) {
	const classes = useStyles();
	// const [ modified, setModified ] = React.useState(false);
	// const [ openAreasSettingsOnGroup, setOpenAreasSettingsOnGroup ] = React.useState();

	// const renderCleaningActivityRowByKey = ({ index, style }) => {
	// 	return (
	// 		<ListItem alignItems="flex-start" button style={style} key={index}>
	// 			<ListItemAvatar>
	// 				<Avatar
	// 					alt={cleaningActivitiesMap[worksheet.activities[index]].title}
	// 					src={cleaningActivitiesMap[worksheet.activities[index]].icon}
	// 				/>
	// 			</ListItemAvatar>
	// 			<ListItemText
	// 				primary={cleaningActivitiesMap[worksheet.activities[index]].title}
	// 				secondary={
	// 					<React.Fragment>
	// 						<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
	// 							{cleaningActivitiesMap[worksheet.activities[index]].description}
	// 						</Typography>
	// 					</React.Fragment>
	// 				}
	// 			/>
	// 			<ListItemText
	// 				primary={cleaningActivitiesMap[worksheet.activities[index]].recurrence.freq}
	// 				secondary={
	// 					<React.Fragment>
	// 						<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
	// 							{cleaningActivitiesMap[worksheet.activities[index]].recurrence.iteration}
	// 						</Typography>
	// 					</React.Fragment>
	// 				}
	// 			/>
	// 		</ListItem>
	// 	);
	// };
	// const renderAreaRowByKey = ({ index, style }) => {
	// 	return (
	// 		<ListItem alignItems="flex-start" button style={style} key={index}>
	// 			<ListItemText
	// 				primary={getAreaName(venue.areas[worksheet.areas[index]])}
	// 				// secondary={
	// 				// 	<React.Fragment>
	// 				// 		<Typography component="span" variant="body2" className={classes.inline} color="textPrimary">
	// 				// 			{cleaningActivitiesMap[activityScheduleItem.activities[index]].description}
	// 				// 		</Typography>
	// 				// 	</React.Fragment>
	// 				// }
	// 			/>
	// 		</ListItem>
	// 	);
	// };

	// const renderRecurrence = () => {
	// 	return (
	// 		<div>
	// 			<Typography variant="h6" gutterBottom>
	// 				Ismétlődés
	// 			</Typography>
	// 			<p1>Ismétlés: </p1>
	// 			<FormControl className={classes.formControl} disabled>
	// 				<NativeSelect
	// 					value={worksheet.rrule.freq}
	// 					// onChange={handleChange}
	// 					name="age"
	// 					className={classes.selectEmpty}
	// 					inputProps={{ 'aria-label': 'freq' }}
	// 				>
	// 					<option value="DAILY">NAPI</option>
	// 					<option value="WEEKLY">HETI</option>
	// 					<option value="MONTHLY">HAVI</option>
	// 				</NativeSelect>
	// 			</FormControl>
	// 			<br />
	// 			{worksheet.rrule.freq == 'WEEKLY' ? renderWeeklyRecurrence() : ''}
	// 			{worksheet.rrule.freq == 'DAILY' ? renderDailyRecurrence() : ''}
	// 		</div>
	// 	);
	// };

	const renderAreas = () => {
		return (
			<div>
				<Typography variant="h6" gutterBottom>
					Területekhez hozzárendelve
				</Typography>
				{typeof venue.positions === 'undefined' ? (
					'Missing'
				) : (
					<AreasAndRulesTableList venue={venue} worksheetId={worksheetId} />
				)}
			</div>
		);
	};
	const renderCleaningActivities = () => {
		return (
			<div>
				<Typography variant="h6" gutterBottom>
					Takarítási feladatok
				</Typography>

				<CleaningActivitiesTable
					venue={venue}
					worksheet={worksheet}
					worksheetId={worksheetId}
					cleaningActivitiesMap={cleaningActivitiesMap}
					setWorksheetIsLoading={setWorksheetIsLoading}
				/>
			</div>
		);
	};
	const renderDailyRecurrence = () => {
		return (
			<div>
				<b>Naponta:</b>
				<br />
				<Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
					<Grid item>
						{worksheet.rrule.byhour.map((element) => (
							<div>
								<p1>{element.startAt + ' - ' + element.finishAt}</p1> <br />
							</div>
						))}
					</Grid>
				</Grid>
			</div>
		);
	};
	const renderWeeklyRecurrence = () => {
		return (
			<div>
				<p1>Ismétlés minden: </p1>
				<TextField
					disabled
					id="standard-number"
					type="number"
					size="small"
					defaultValue={worksheet.rrule.interval}
					InputLabelProps={{
						shrink: true
					}}
				/>
				<p1> héten</p1>
			</div>
		);
	};

	return (
		<Accordion style={{ flex: 1 }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1c-content"
				id="panel1c-header"
				className="panel1cheader"
			>
				<Grid container className={classes.expandable}>
					<Grid item xs={12}>
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid key={'value'} item>
								<Typography variant="h6" gutterBottom>
									{worksheet.name}
								</Typography>
								<Typography variant="subtitle1">{worksheet.description}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<Divider />
			<AccordionDetails>
				<Box sx={{ flexGrow: 1 }}>
					{/* {renderRecurrence()}
						<br /> */}
					{renderCleaningActivities()}
					<br />
					{renderAreas()}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
