import React, { useEffect } from 'react';
import UUID from 'uuid/v4';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import {
	getWorksheetsForOrgOnVenue,
	getWorkerPositionsForOrgOnVenue,
	createNewWorksheet,
	deleteWorksheet
} from '../../../api/manager';

import { getSelectedOrg } from '../../../firebase/auth';
import WorksheetsExpansionView from './WorksheetsExpansionView';

import { Grid, Typography, IconButton, Box } from '@mui/material';
// import AddCleaningActivityViewDialog from './AddCleaningActivityViewDialog';
import CalendarViewDialog from './CalendarViewDialog';
// import SchedulerView from './SchedulerView';
import Skeleton from '@mui/material/Skeleton';

import SimpleStringInputDialog from '../../../utilities/SimpleStringInputDialog';

function isWorksheetIsInUseOnWorkerposition(worksheetId, venue) {
	var isInUse = false;
	Object.values(venue.positions).forEach((position) => {
		Object.keys(position.areas).forEach((areaKey) => {
			if (Object.keys(position.areas[areaKey]).includes(worksheetId)) {
				isInUse = true;
			}
		});
	});
	return isInUse;
}

function ManageWorksheets({ venue, updateBrowserHistory, cleaningActivities, cleaningActivitiesMap }) {
	const [ isOpenDialog, setIsOpenDialog ] = React.useState(false);
	const [ isOpenCalendarDialog, setIsOpenCalendarDialog ] = React.useState(false);
	const [ worksheets, setWorksheets ] = React.useState({});
	const [ worksheetsIsLoading, setWorksheetsIsLoading ] = React.useState(false);

	useEffect(() => {
		if (venue.worksheets == null) {
			fetchWorksheets();
		} else {
			setWorksheets(venue.worksheets);
		}
		if (venue.positions == null) {
			fetchWorkerPositions();
		}
	}, []);

	const fetchWorksheets = () => {
		setWorksheetsIsLoading(true);
		getWorksheetsForOrgOnVenue(getSelectedOrg(), venue.id)
			.then((result) => {
				setWorksheets(result);
				setWorksheetsIsLoading(false);
				venue.worksheets = result;
			})
			.catch((error) => {
				console.log('Error happened in ManageWorksheets.js while retriving worksheets' + error);
			});
	};

	const fetchWorkerPositions = () => {
		getWorkerPositionsForOrgOnVenue(getSelectedOrg(), venue.id)
			.then((result) => {
				venue.positions = result;
			})
			.catch((error) => {
				console.log('Error happened in ManageWorksheets.js while fetching positions' + error);
			});
	};

	// const openCalendarView = () => {
	// 	setIsOpenCalendarDialog(true);
	// };
	const addAreaToCleaningActivity = (key) => {
		setIsOpenCalendarDialog(true);

		// var groups = name.split(';');
		// groups.map((groupname) => {
		// 	venue.groups[UUID()] = { name: groupname, areas: [] };
		// });
		// setOpenNewGroupDialog(false);
		// updateVenueWithGroup();
	};

	// const addCleaningActivityToAreas = () => {
	// 	setIsOpenDialog(true);
	// 	// var groups = name.split(';');
	// 	// groups.map((groupname) => {
	// 	// 	venue.groups[UUID()] = { name: groupname, areas: [] };
	// 	// });
	// 	// setOpenNewGroupDialog(false);
	// 	// updateVenueWithGroup();
	// };

	const openCreateNewWorksheetDialog = () => {
		setIsOpenDialog(true);
	};

	const saveWorkSheet = (workSheetName) => {
		setWorksheetsIsLoading(true);
		let newWorksheet = { id: UUID(), name: workSheetName };
		createNewWorksheet(getSelectedOrg(), venue.id, newWorksheet)
			.then((result) => {
				setWorksheetsIsLoading(false);
				worksheets[newWorksheet.id] = newWorksheet;
				setWorksheets({ ...worksheets });
				venue.worksheets[newWorksheet.id] = newWorksheet;
			})
			.catch((error) => {
				console.log('Error happened in ManageWorksheets.js while creating new worksheet' + error);
			});
	};

	const deleteWorksheetById = (worksheetId, element) => {
		setWorksheetsIsLoading(true);
		if (isWorksheetIsInUseOnWorkerposition(worksheetId, venue) == true) {
			setWorksheetsIsLoading(false);
			alert('Feladatlista törlése nem lehetséges amíg használatban van.');
		} else {
			deleteWorksheet(getSelectedOrg(), venue.id, worksheetId)
				.then((result) => {
					setWorksheetsIsLoading(false);
					let tempworksheets = { ...worksheets };
					delete tempworksheets[worksheetId];
					delete venue.worksheets[worksheetId];
					setWorksheets(tempworksheets);
				})
				.catch((error) => {
					console.log('Error happened in ManageWorksheets.js while creating new worksheet' + error);
				});
		}
	};

	return (
		<div>
			{/* {isOpenDialog ? (
				<AddCleaningActivityViewDialog
					isOpenDialog={isOpenDialog}
					setIsOpenDialog={setIsOpenDialog}
					cleaningActivities={cleaningActivities}
				/>
			) : null} */}
			{/* {isOpenCalendarDialog ? (
				<CalendarViewDialog
					isOpenCalendarDialog={isOpenCalendarDialog}
					setIsOpenCalendarDialog={setIsOpenCalendarDialog}
					workerPositions={workerPositions}
				/>
			) : null} */}
			<SimpleStringInputDialog
				open={isOpenDialog}
				setOpen={setIsOpenDialog}
				title="Új fealdatlista hozzáadása"
				description="Feladatlista létrehozásához adja meg a feladatlista címét"
				inputText="Feladatlista címe"
				inputType="string"
				saveInput={saveWorkSheet}
			/>

			<Grid container alignItems="flex-start" justifyContent="flex-end">
				<Grid item xs={11}>
					<Typography align="left" variant="h6">
						Feldatlisták
					</Typography>
				</Grid>
				{/* <Grid item xs={1}>
					<IconButton
						onClick={() => {
							openCalendarView(true);
						}}
						aria-label="open"
					>
						<DateRangeOutlinedIcon />
					</IconButton>
				</Grid> */}
				<Grid item xs={1}>
					<IconButton
						onClick={() => {
							openCreateNewWorksheetDialog(true);
						}}
						aria-label="edit"
					>
						<AddIcon />
					</IconButton>
				</Grid>
				<Grid item xs={12}>
					{worksheetsIsLoading ? (
						<React.Fragment>
							<Skeleton
								animation="wave"
								variant="rect"
								width="100%"
								height={100}
								style={{ marginBottom: 15 }}
							/>
							<Skeleton animation="wave" variant="rect" width="100%" height={100} />
						</React.Fragment>
					) : Object.keys(worksheets).length > 0 ? (
						Object.keys(worksheets).map((element, index) => (
							<Stack key={index} direction="row" alignItems="flex-start">
								<WorksheetsExpansionView
									worksheet={worksheets[element]}
									worksheetId={element}
									cleaningActivitiesMap={cleaningActivitiesMap}
									venue={venue}
									setWorksheetIsLoading={setWorksheetsIsLoading}
								/>
								<IconButton
									onClick={() => {
										if (window.confirm('Biztosan törölni szeretné a feladatlistát?'))
											deleteWorksheetById(worksheets[element].id, element);
									}}
									aria-label="edit"
								>
									<DeleteIcon />
								</IconButton>
							</Stack>
						))
					) : (
						<Box m={3}>
							<Typography align="center" variant="h5">
								Jelenleg nincs feladatlista létrehozva. Új feladatlista létrehozásához kattints a + gombra a
								jobb felső sarokban!
							</Typography>
						</Box>
					)}
				</Grid>

				<Grid item xs={12} />
			</Grid>
		</div>
	);
}

export default ManageWorksheets;
