import React, { useEffect } from 'react';

import VenueSelector from '../../utilities/VenueSelector';
import { useHistory } from 'react-router-dom';

import {
	getTemplates,
	getUsersOnOrg,
	getCleaningActivitiesForOrg
} from '../../api/manager';
import { getSelectedOrg } from '../../firebase/auth';

function Venues() {
	const history = useHistory();
	const [ templates, setTemplates ] = React.useState([]);
	const [ users, setUsers ] = React.useState([]);
	const [ cleaningActivities, setCleaningActivities ] = React.useState([]);
	const [ templatesMap, setTemplatesMap ] = React.useState([]);
	const [ cleaningActivitiesMap, setCleaningActivitiesMap ] = React.useState({});
	var isMounted = true;

	useEffect(() => {
		isMounted = true;
		if (templates.length == 0) fetchTemplates();
		if (cleaningActivities.length == 0) fetchCleaningActivities();
		if (users.length == 0) fetchUsers();
		return () => {
			isMounted = false;
		};
	}, []);

	const handleVenueSelected = (venue) => {
		history.push({
			pathname: `${history.location.pathname}/${venue.name}`,
			search: `?id=${venue.id}`,
			state: {
				venue: venue,
				templates: templates,
				templatesMap: templatesMap,
				users: users,
				cleaningActivities: cleaningActivities,
				cleaningActivitiesMap: cleaningActivitiesMap
			}
		});
	};
	const fetchUsers = () => {
		getUsersOnOrg(getSelectedOrg())
			.then((result) => {
				if (isMounted) {
					setUsers(result.data.results);
				}
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving users' + error);
			});
	};
	const fetchTemplates = () => {
		getTemplates(getSelectedOrg())
			.then((result) => {
				if (isMounted) {
					setTemplates(result);
					var templatesMap = {};
					result.map((template) => (templatesMap[template.id] = template));
					setTemplatesMap(templatesMap);
				}
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving templates' + error);
			});
	};

	const fetchCleaningActivities = () => {
		getCleaningActivitiesForOrg(getSelectedOrg())
			.then((result) => {
				// setCleaningActivities(result);
				// var tempCleaningActivitiesMap = {};
				// result.map((cleaningActivity) => (tempCleaningActivitiesMap[cleaningActivity.id] = cleaningActivity));
				if (isMounted) {
					setCleaningActivitiesMap(result);
				}
			})
			.catch((error) => {
				console.log('Error happened in Venues.js while retriving cleaning activities' + error);
			});
	};

	
	return (
			<VenueSelector selectVenue={handleVenueSelected} showAddNewVenue={true} showAllVenues={true} />
	);
}

export default Venues;
