import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, TextField, Box, Typography, Select, MenuItem } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const daysArray = [ 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU' ];
const weekArray = [ 1, 2, 3, 4, -1 ];

const translateWeekKey = (key) => {
	if (key == 1) {
		return 'Első';
	} else if (key == 2) {
		return 'Második';
	} else if (key == 3) {
		return 'Harmadik';
	} else if (key == 4) {
		return 'Negyedik';
	} else if (key == -1) {
		return 'Utolsó';
	}
};

const translateDayKey = (key) => {
	if (key == 'MO') {
		return 'Hétfő';
	} else if (key == 'TU') {
		return 'Kedd';
	} else if (key == 'WE') {
		return 'Szerda';
	} else if (key == 'TH') {
		return 'Csütörtök';
	} else if (key == 'FR') {
		return 'Péntek';
	} else if (key == 'SA') {
		return 'Szombat';
	} else if (key == 'SU') {
		return 'Vasárnap';
	}
};

function getDaysByWorksheet(worksheet) {
	if(worksheet.byday && worksheet.byday.length > 0){
		return worksheet.byday;
	} else {
		return ["MO"]
	}
}

function getWeekByWorksheet(worksheet) {
	if (worksheet.freq == 'MONTHLY') {
		return worksheet.bysetpos;
	}
	return null;
}

export default function MonthlyRRulePicker({ worksheet, editable }) {
	const [ days, setDays ] = React.useState(getDaysByWorksheet(worksheet));
	const [ weekIndex, setWeekIndex ] = React.useState(getWeekByWorksheet(worksheet));

	const handleDayChange = (event) => {
		setDays([ event.target.value ]);
		worksheet.byday = [ event.target.value ];
	};

	const handleWeekChange = (event) => {
		setWeekIndex(event.target.value);
		worksheet.bysetpos = event.target.value;
	};

	return (
		<Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
			{/* <Grid container spacing="2" direction="row" justifyContent="flex-start" alignItems="center"> */}
			<Grid item>
				<Typography variant="body2" color={editable ? 'textPrimary' : 'textSecondary'} align="center">
					Minden
				</Typography>
			</Grid>
			<Grid item>
				<Select
					disabled={!editable}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={weekIndex}
					onChange={handleWeekChange}
				>
					{weekArray.map((element, index) => <MenuItem value={index}>{translateWeekKey(element)}</MenuItem>)}
				</Select>
			</Grid>
			<Grid item>
				<Typography variant="body2" color={editable ? 'textPrimary' : 'textSecondary'} align="center">
					héten
				</Typography>
			</Grid>
			<Grid item>
				<Select
					disabled={!editable}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={days[0]}
					onChange={handleDayChange}
				>
					{daysArray.map((element, index) => <MenuItem value={element}>{translateDayKey(element)}</MenuItem>)}
				</Select>
			</Grid>
		</Grid>
	);
}
