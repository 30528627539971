import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import BackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import SchedulerView from './SchedulerView';
import CalendarView from './CalendarView';

//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: 10,
		backgroundColor: '#FFFFFF'
	}
}));
const events = [
	{
		start: moment().toDate(),
		end: moment().add(1, 'days').toDate(),
		title: 'Some title'
	}
];
const localizer = momentLocalizer(moment);

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

export default function CalendarViewDialog({ isOpenCalendarDialog, setIsOpenCalendarDialog, workerPositions }) {
	const classes = useStyles();

	const handleClick = () => {
		setIsOpenCalendarDialog(false);
	};

	return (
		<div>
			{' '}
			<Dialog
				fullScreen
				open={isOpenCalendarDialog}
				onClose={(event) => setIsOpenCalendarDialog(false)}
				TransitionComponent={Transition}
			>
				<Box className={classes.root} boxShadow={3}>
					<Grid container direction="row" justifyContent="flex-start">
						<Grid key="back-button" item>
							<IconButton aria-label="back" size="small" onClick={handleClick}>
								<BackIcon fontSize="small" />
							</IconButton>
						</Grid>
						<Grid key="venue-title" item>
							<Typography
								component="span"
								variant="subtitle1"
								color="colorPrimary"
								display="initial"
								align="left|center"
							>
								Naptár nézet
							</Typography>

							<CalendarView workerPositions={workerPositions} />
						</Grid>
					</Grid>
				</Box>
			</Dialog>
		</div>
	);
}
