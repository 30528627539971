import axios from 'axios';
import { firebaseAuth } from '../firebase/firebase';

import moment from 'moment';

// axios.defaults.baseURL = 'https://us-central1-cleanspect.cloudfunctions.net';
const firebaseAuthKey = 'firebaseAuthInProgress';
const baseURL =
	process.env.NODE_ENV === 'production'
		? 'https://europe-west2-cleanspect-prod.cloudfunctions.net'
		: 'https://us-central1-cleanspect-stage.cloudfunctions.net';

 const instance = axios.create({
	baseURL: baseURL,
	timeout: 100000
});

export function getVenues(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getVenuesForOrg?org=${org}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getVenuesWithAreas(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getVenuesForOrg?org=${org}&includeAreas=${true}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getCustomerVenues(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getCustomerVenuesForOrg?org=${org}&includeAreas=${true}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getMetricsForOrg(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getMetricsForOrg?org=${org}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response.data);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getWorkLogs(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getWorkLogs?org=${org}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getWorkLogsOnVenue(org, venueId, day) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						var dayString = moment(day).format('YYYY.MM.DD')
						var startDate = moment(dayString, 'YYYY.MM.DD').format()
						var utcStartDate = moment.utc(startDate)
						var endDate = moment(utcStartDate).add(1, 'days')
						return instance.get(
							`/getWorkLogsOnVenue?org=${org}&venueId=${venueId}&startAt=${utcStartDate.toString()}&endAt=${endDate.toString()}`,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getWorkLogsOnVenueForReport(org, venueId,fromDate,endDate) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						// var dayString = moment().format('YYYY.MM.DD')
						// var startDate = moment(dayString, 'YYYY.MM.DD').format()
						// var utcStartDate = moment.utc(startDate)
						// var endDate = moment(utcStartDate).add(1, 'days')
						return instance.get(
							`/getWorkLogsOnVenue?org=${org}&venueId=${venueId}&startAt=${fromDate}&endAt=${endDate}`,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getWorkLogsOnVenueForMonth(org, venueId, month) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(
							`/getWorkLogsOnVenue?org=${org}&venueId=${venueId}&month=${moment(month).format(
								'YYYY.MM.'
							)}`,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getUsersOnOrg(org) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.get(`/getUsersOnOrg?org=${org}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function saveVenue(org, venue) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/saveVenueForOrg?org=${org}`, venue, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function createNewWorksheet(org, venueId, newWorkSheet) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/createNewWorksheet?org=${org}&venueId=${venueId}`, newWorkSheet, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateVenue(org, venue) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/updateVenueForOrg?org=${org}`, venue, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateAreasOnWorkerPosition(org, venueId, workerPositionId, areas) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateAreasOnWorkerPosition?org=${org}&venueId=${venueId}&workerPositionId=${workerPositionId}`,
							areas,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateWorkerPosition(org, venueId, workerPositionId, workerPosition) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateWorkerPosition?org=${org}&venueId=${venueId}&workerPositionId=${workerPositionId}`,
							workerPosition,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteWorkerPosition(org, venueId, workerPositionId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.delete(
							`/deleteWorkerPosition?org=${org}&venueId=${venueId}&workerPositionId=${workerPositionId}`,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateWorksheetActivitiesOnVenue(org, venueId, cleaningActivities, worksheetId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateWorksheetActivitiesOnVenue?org=${org}&venueId=${venueId}&worksheetId=${worksheetId}`,
							cleaningActivities,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteWorksheet(org, venueId, worksheetId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.delete(
							`/deleteWorksheet?org=${org}&venueId=${venueId}&worksheetId=${worksheetId}`,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getCleaningActivitiesForOrg(org) {
	return getIdToken()
		.then((result) => {
			return instance.get(`/getCleaningActivitiesForOrg?org=${org}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function updateMasterCleaningActivitiesForOrg(org, masterCleaningActivities) {
	// return generalPostAPICall(`/updateMasterCleaningActivitiesForOrg?org=${org}`, masterCleaningActivities);
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateMasterCleaningActivitiesForOrg?org=${org}`,
							masterCleaningActivities,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteMasterCleaningActivitiesForOrg(org, cleaningActivitiesToDelete) {
	// return generalPostAPICall(`/updateMasterCleaningActivitiesForOrg?org=${org}`, masterCleaningActivities);
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						return instance.post(
							`/deleteMasterCleaningActivitiesForOrg?org=${org}`,
							cleaningActivitiesToDelete,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function generalPostAPICall(url, body) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(url, body, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getWorksheetsForOrgOnVenue(org, venueId) {
	return getIdToken()
		.then((result) => {
			return instance.get(`/getWorksheetsForOrgOnVenue?org=${org}&venueId=${venueId}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function getWorkerPositionsForOrgOnVenue(org, venueId) {
	return getIdToken()
		.then((result) => {
			return instance.get(`/getWorkerPositionsForOrgOnVenue?org=${org}&venueId=${venueId}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function deleteAreaOnVenueForOrg(org, venueId, areaId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&venueId=${venueId}&areaId=${areaId}`;
						return instance.delete(`/deleteAreaOnVenueForOrg?${query}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteAreasOnVenueForOrg(org, venueId, areas) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&venueId=${venueId}`;
						return instance.post(
							`/deleteAreasOnVenueForOrg?${query}`,
							{ areasToDelete: areas },
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateOrCreateAreasOnOrgAndVenue(org, venueId, areas) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/updateOrCreateAreasOnOrgAndVenue?org=${org}&venueId=${venueId}`, areas, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteCategoryOnVenueForOrg(org, venueId, categoryId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&venueId=${venueId}&categoryId=${categoryId}`;
						return instance.delete(`/deleteCategoryOnVenueForOrg?${query}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateOrCreateCategoriesOnOrgAndVenue(org, venueId, categories) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateOrCreateCategoriesOnOrgAndVenue?org=${org}&venueId=${venueId}`,
							categories,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteGroupOnVenueForOrg(org, venueId, groupId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&venueId=${venueId}&groupId=${groupId}`;
						return instance.delete(`/deleteGroupOnVenueForOrg?${query}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function updateOrCreateGroupsOnOrgAndVenue(org, venueId, groups) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(
							`/updateOrCreateGroupsOnOrgAndVenue?org=${org}&venueId=${venueId}`,
							groups,
							{
								headers: { Authorization: 'Bearer ' + result }
							}
						);
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function saveTemplate(org, template) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/saveTemplateForOrg?org=${org}`, template, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function saveTemplates(org, templates) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						console.log('Result of getIdToken: ' + result);
						return instance.post(`/saveTemplatesForOrg?org=${org}`, templates, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function getTemplates(org) {
	return getIdToken()
		.then((result) => {
			return instance.get(`/getTemplatesForOrg?org=${org}`, { headers: { Authorization: 'Bearer ' + result } });
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function getMe() {
	return getIdToken()
		.then((result) => {
			return instance.get(`/getMe`, { headers: { Authorization: 'Bearer ' + result } });
		})
		.then((response) => {
			if (response) return response.data;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function deleteTemplate(org, templateId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&id=${templateId}`;
						return instance.delete(`/deleteTemplateForOrg?${query}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

export function deleteVenue(org, venueId) {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				return user
					.getIdToken()
					.then((result) => {
						var query = `org=${org}&id=${venueId}`;
						return instance.delete(`/deleteVenueForOrg?${query}`, {
							headers: { Authorization: 'Bearer ' + result }
						});
					})
					.then((response) => {
						console.log(response.data);
						resolve(response);
					})
					.catch((error) => {
						console.log('Error at getIdToken: ' + error);
						reject(error);
					});
				// User is signed in.
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
			}
		});
	});
}

function getIdToken() {
	return new Promise((resolve, reject) => {
		firebaseAuth().onAuthStateChanged((user) => {
			if (user) {
				resolve(user.getIdToken());
			} else {
				localStorage.removeItem(firebaseAuthKey);
				console.log('User not logged in');
				reject('User not logged in');
			}
		});
	});
}

export function getReportsByVenueId(org, venueId, startAt, endAt) {
	return getIdToken()
		.then((result) => {
			var query = `org=${org}&id=${venueId}&startAt=${startAt}&endAt=${endAt}`;
			return instance.get(`/getSubmittedReportsByVenueForOrg?${query}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function getAdHocTasksByVenueId(org, venueId) {
	return getIdToken()
		.then((result) => {
			var query = `org=${org}&venueId=${venueId}`;
			return instance.get(`/getAllTasksForOrgAtVenueInObject?${query}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function getSubmittedReportsByVenueForOrg_GroupedByAreagroups(org, venueId, startAt, endAt) {
	return getIdToken()
		.then((result) => {
			var query = `org=${org}&venueId=${venueId}&startAt=${startAt}&endAt=${endAt}`;
			return instance.get(`/getSubmittedReportsByVenueForOrg_GroupedByAreagroups?${query}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.results;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}

export function getReportWithArea(org, venueId, reportId) {
	return getIdToken()
		.then((result) => {
			var query = `org=${org}&venueId=${venueId}&reportId=${reportId}`;
			return instance.get(`/getSubmittedReportByIdAndVenueForOrg?${query}`, {
				headers: { Authorization: 'Bearer ' + result }
			});
		})
		.then((response) => {
			if (response.data) return response.data.report;
		})
		.catch((error) => {
			console.log('Error catched: ' + error);
		});
}
