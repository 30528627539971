import React, { useEffect } from 'react';
import AreaAndActivities from './AreaAndActivities';

export default function AreasAndActivityList({ venue, worksheets, positionAreas }) {
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<div key={venue.name}>
				{Object.keys(positionAreas).map((areaId) => (
					<div key={areaId}>
						<AreaAndActivities
							area={venue.areas[areaId]}
							venue={venue}
							worksheets={worksheets}
							positionAreas={positionAreas}
						/>
					</div>
				))}
			</div>
		</div>
	);
}
