import React, { useEffect, forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';

import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import DeleteOutlined from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Button, Grid, Box, Typography } from '@mui/material';

const columns = [
	{ title: 'Épület', field: 'building' },
	{ title: 'Terület', field: 'name' },
	{ title: 'Kategória', field: 'category' },
	{
		title: 'Hozzáadva',
		field: 'rank',
		defaultSort: 'desc',
		render: (rowData) => (rowData.checked ? <Check /> : <Clear />)
	}
];

const theme = createTheme({
	palette: {
		secondary: {
			main: '#FFFFFF'
		}
	}
});

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
	DeleteOutlined: forwardRef((props, ref) => <DeleteOutlined {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function SelectAreasTable({ position, venue, selectedAreas, setSelectedAreas, setActiveStep }) {
	const [ rows, setRows ] = React.useState([]);
	const [ selectedRows, setSelectedRows ] = React.useState(selectedAreas);

	useEffect(() => {
		generateDataFromVenue(selectedRows);
	}, []);

	const handleCheckboxClick = (value) => {
		let tempSelectedRows = selectedRows;
		if (tempSelectedRows.includes(value)) {
			var index = tempSelectedRows.indexOf(value);
			if (index !== -1) {
				tempSelectedRows.splice(index, 1);
			}
		} else {
			tempSelectedRows.push(value);
		}
		setSelectedRows(tempSelectedRows);
		generateDataFromVenue(tempSelectedRows);
	};

	const generateDataFromVenue = (selectedRows) => {
		var rows = [];
		Object.keys(venue.areas).forEach(function(key) {
			let area = venue.areas[key];
			rows.push({
				building: area.building,
				name: `${area.floor ? area.floor + '. emelet' : ''} ${area.name} ${area.number}`,
				category: venue.categories[area.category] ? venue.categories[area.category].name : '',
				checked: selectedRows.includes(area.id),
				checkedBefore: Object.keys(position.areas).includes(area.id),
				rank: getRankForRow(Object.keys(position.areas).includes(area.id), selectedRows.includes(area.id)),
				id: key
			});
		});
		setRows(rows);
	};

	const getBackgroundColorForRow = (rowData) => {
		if (!rowData.checkedBefore && rowData.checked) {
			return '#4fc3f7';
		}
		if (rowData.checkedBefore && rowData.checked) {
			return '#aed581';
		}
		if (rowData.checkedBefore && !rowData.checked) {
			return '#ff8a65';
		}
		return '#FFF';
	};
	const getRankForRow = (checkedBefore, checked) => {
		if (!checkedBefore && checked) {
			return 2;
		}
		if (checkedBefore && checked) {
			return 3;
		}
		if (checkedBefore && !checked) {
			return 1;
		}
		return 0;
	};
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid item>
					<Box component="span" m={1}>
						<Typography variant="h5">Területek kiválasztva: {selectedRows.length}</Typography>
					</Box>
				</Grid>

				<Grid item>
					<Box component="span" m={1}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setSelectedAreas(selectedRows);
								setActiveStep(2);
							}}
						>
							Következő
						</Button>
					</Box>
				</Grid>
			</Grid>

			<ThemeProvider theme={theme}>
				<MaterialTable
					icons={tableIcons}
					columns={columns}
					data={rows}
					options={{
						showTitle: false,
						filtering: true,
						sorting: true,
						search: false,
						paging: false,
						toolbar: false,
						padding: 'dense',
						selection: true,
						grouping: true,
						showSelectAllCheckbox: false,
						selectionProps: (rowData) => ({
							checked: rowData.checked === true,
							value: rowData.id,
							onClick: (event, rowData) => handleCheckboxClick(event.target.value)
						}),

						rowStyle: (rowData) => ({
							backgroundColor: getBackgroundColorForRow(rowData)
						})
					}}
				/>
			</ThemeProvider>
		</div>
	);
}
