import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, TextField, Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function getIntervalByWorksheet(worksheet) {
	if (worksheet.interval != null) {
		return worksheet.interval;
	} else {
		return 1;
	}
}

export default function WeeklyRRulePicker({ worksheet, editable }) {
	const [ interval, setInterval ] = React.useState(getIntervalByWorksheet(worksheet));

	useEffect(() => {
		// generateAreasWithActivities();
		if (worksheet.interval == null) {
			worksheet.interval = 1;
		}
	}, []);

	const handleIntervalChange = (e) => {
		setInterval(e.target.value);
		worksheet.interval = e.target.value;
	};

	return (
		<Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
			{/* <Grid container spacing="2" direction="row" justifyContent="flex-start" alignItems="center"> */}
			<Grid item>
				<Typography variant="body2" color={editable ? 'textPrimary' : 'textSecondary'} align="center">
					Naponta
				</Typography>
			</Grid>
			<Grid item>
				<Box width={60}>
					<TextField
						id="standard-number"
						value={interval}
						onChange={handleIntervalChange}
						type="number"
						min="1"
						size="small"
						disabled={!editable}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}
