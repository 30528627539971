import React from 'react';
import { makeStyles } from '@mui/styles';
import { getAreaNameWithoutBuilding } from '../utilities/StringHelper';

import moment from 'moment';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import WorklogCard from '../utilities/worklogcard/WorkLogCard'


import { styled } from '@mui/material/styles';


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';


const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		padding: 10
	}
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: 'center',
	color: theme.palette.text.secondary,
  }));

export default function WorkLogsTracker({ data, venueSettings }) {
	const classes = useStyles();
	const [ worker, setWorker ] = React.useState(Object.keys(data)[0]);

	const handleChange = (event) => {
		setWorker(event.target.value);
	};

	return data[worker] != null ? (
			<Grid container direction="column"
			justifyContent="flex-start" alignItems="flex-start">
				<Grid item>
					{Object.keys(data).map((key) => (
						<div style={{width:'90vw'}}>

							<h3> {data[key].user.lastName} {data[key].user.firstName} </h3>
							<Box>
							<Grid spacing={3} container direction="row" justifyContent="flex-start" alignItems="flex-start">
							{data[key].openWorkLogs.map((value,it) => (
								<Grid item>
										<WorklogCard workLog={value} isOpenWorkLog={true} venueSettings={venueSettings}/> 
								</Grid>
							))}
							{data[key].closedWorkLogs.map((value,it) => (
								<Grid item>
									<WorklogCard workLog={value} isOpenWorkLog={false} venueSettings={venueSettings}/> 
								</Grid>
							))}
							</Grid>
								{/* <List style={{maxWidth: '100%', overflow: 'auto',  justifyContent:'flex-start', flexDirection: 'row',   display: 'flex'}}>
									{data[key].openWorkLogs.map((value,it) => (
										<ListItem>
											<WorklogCard workLog={value} isOpenWorkLog={true} venueSettings={venueSettings}/> 
										</ListItem>
									))}
									{data[key].closedWorkLogs.map((value,it) => (
										<ListItem>
											<WorklogCard workLog={value} isOpenWorkLog={false} venueSettings={venueSettings}/> 
										</ListItem>
									))}
								</List> */}
							</Box>							
</div>

					))}
				</Grid>
			</Grid>
	
	) : (
		'Nincs bejelentkezés erre a napra'
	);
}
