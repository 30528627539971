import React, { forwardRef, useEffect } from 'react';

import { withStyles } from '@mui/styles';
import { Grid, Typography, IconButton, Chip, Divider, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RRulePicker from './Card/RRulePicker/RRulePicker';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import WorksheetCard from './Card/WorksheetCard';

export default function WorksheetsOnArea({ worksheets, positionArea, editable }) {
	const [ temp, setTemp ] = React.useState(0);
	const deleteWorksheetCardOnPositionArea = (worksheetKey) => {
		delete positionArea[worksheetKey];
		setTemp();
	};
	return (
		<Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
			{Object.keys(positionArea).map((key) => (
				<Grid item key={key}>
					{worksheets ? (
						<WorksheetCard
							worksheetOnArea={positionArea[key]}
							editable={editable}
							worksheet={worksheets[key]}
							deleteWorksheetCard={deleteWorksheetCardOnPositionArea}
							worksheetId={key}
						/>
					) : (
						''
					)}
				</Grid>
			))}
		</Grid>
	);
}
